import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import {
    clearRefinements,
    configure,
    index, hits, menu, menuSelect, pagination,
    panel, rangeSlider, ratingMenu,
    refinementList,
    searchBox, sortBy, stats
} from 'instantsearch.js/es/widgets';
import {XhrService} from "../../../../default/global/ts/xhr.service";
//import {initOpenFavoriteListModal} from "../../../../default/global/js/favoritesLists";
import {
    connectMenu,
    connectPagination,
    connectRange,
    connectCurrentRefinements,
    connectRatingMenu
} from "instantsearch.js/es/connectors";
import { singleIndex } from 'instantsearch.js/es/lib/stateMappings';
import hoverintent from "../../../../default/global/js/hoverintent";
import {loadScript} from "../../../../default/global/js/utilities/loadScript";
import {lottieLoader} from "../../../../default/global/js/lottieLoader";
import {fadeOut} from "../../../../default/global/js/utilities/fade";
import {Tooltip} from "../../../../default/global/js/tooltip";


export function algoliaSearch() {
    updateRobotContent();
    const searchClient = algoliasearch(algoliaAppId, algoliaSearchKey);

    const search = instantsearch({
        indexName: algoliaIndexName,
        indexId: 'instant_search_one',
        searchClient,
        numberLocale: 'de',
        future: {
            preserveSharedStateOnUnmount: true,
        },

    });

    search.on('render', () => {

        showOrHideFilters();

        algoliaListUpdate();
        showSearchBars();


    });
    let searchbox = document.querySelector('#searchbox');
    let ag_template = '';
    if (display) {
        if (display == 'tile') {
            ag_template = "algolia-hit-template-tile";
        } else {
            ag_template = "algolia-hit-template-list";
        }
    }
    const renderPagination = (renderOptions, isFirstRender) => {
        const {
            pages, currentRefinement, nbPages, isFirstPage, isLastPage, refine, createURL,
        } = renderOptions;

        const containers = document.querySelectorAll('.pagination-container');
        let textPages = GLOBALS.tc.pagesText;
        let textFrom = GLOBALS.tc.from;
        containers.forEach(container => {
        if (nbPages > 1) {

            container.innerHTML = `
            <ul class="pagination">
                ${!isFirstPage ? `                                  
                        <a class="pagination__item pagination__item--prev" href="${createURL(currentRefinement - 1)}" data-value="${currentRefinement - 1}">
                            <i class="icon icon-angle-left"></i>
                        </a>
                        ${currentRefinement >1 ? `
                        <li class="pagination__item">
                             <a href="${createURL(0)}" data-value="${0}" >
                                ${1}
                             </a>
                        </li>
                        ` : ''}
                         
                         ${currentRefinement >2 ? `...` : ''}  
                                               
                        <li class="pagination__item"> 
                              <a href="${createURL(currentRefinement-1)}"  data-value="${currentRefinement-1}" >
                                ${currentRefinement}
                              </a>
                            </li> 
                ` : '' }
                        
                            <li class="pagination__item pagination__item--active">
                              <a href="${createURL(currentRefinement)}"  data-value="${currentRefinement}" 
                                    class="${currentRefinement === currentRefinement ? 'is-active' : ''}">
                                ${currentRefinement + 1}
                              </a>
                            </li>
                                        
              
                
                
                ${!isLastPage ? `
                               <li class="pagination__item">
                              <a href="${createURL(currentRefinement+1)}"  data-value="${currentRefinement+1}" >
                                ${currentRefinement + 2}
                              </a>
                            </li>
                            ${currentRefinement < (nbPages - 3) ? `...` : ''} 
                               ${currentRefinement < (nbPages - 2) ? `
                        <li class="pagination__item">
                                <a href="${createURL(nbPages - 1)}" data-value="${nbPages - 1}" >
                                  ${nbPages}
                                </a>
                            </li> 
                        ` : ''}
                            
                            <a class="pagination__item pagination__item--next" href="${createURL(currentRefinement + 1)}" data-value="${currentRefinement + 1}" >
                                <i class="icon icon-angle-right"></i>
                            </a> 
                ` : ''}
            </ul>
          
            `;

            [...container.querySelectorAll('a')].forEach(element => {
                element.addEventListener('click', event => {
                    window.scrollTo({top: document.getElementById('userSearch').getBoundingClientRect().top + window.pageYOffset - document.querySelector('.headerMain').offsetHeight});
                    event.preventDefault();
                    refine(event.currentTarget.dataset.value);

                });
            });
        }else{
            container.innerHTML = ``;
        }
        });
    };
    const customPagination = connectPagination(renderPagination);

    const renderPaginationContent = (renderOptions, isFirstRender) => {
        const {
            pages, currentRefinement, nbPages, isFirstPage, isLastPage, refine, createURL,
        } = renderOptions;

        const containers = document.querySelectorAll('.pagination-container-content');
        let textPages = GLOBALS.tc.pagesText;
        let textFrom = GLOBALS.tc.from;
        containers.forEach(container => {
        if (nbPages > 1) {

            container.innerHTML = `
            <ul class="pagination">
                ${!isFirstPage ? `                                  
                        <a class="pagination__item pagination__item--prev" href="${createURL(currentRefinement - 1)}" data-value="${currentRefinement - 1}">
                            <i class="icon icon-angle-left"></i>
                        </a>
                        ${currentRefinement >1 ? `
                        <li class="pagination__item">
                             <a href="${createURL(0)}" data-value="${0}" >
                                ${1}
                             </a>
                        </li>
                        ` : ''}
                         
                         ${currentRefinement >2 ? `...` : ''}  
                                               
                        <li class="pagination__item"> 
                              <a href="${createURL(currentRefinement-1)}"  data-value="${currentRefinement-1}" >
                                ${currentRefinement}
                              </a>
                            </li> 
                ` : '' }
                        
                            <li class="pagination__item pagination__item--active">
                              <a href="${createURL(currentRefinement)}"  data-value="${currentRefinement}" 
                                    class="${currentRefinement === currentRefinement ? 'is-active' : ''}">
                                ${currentRefinement + 1}
                              </a>
                            </li>
                                        
              
                
                
                ${!isLastPage ? `
                               <li class="pagination__item">
                              <a href="${createURL(currentRefinement+1)}"  data-value="${currentRefinement+1}" >
                                ${currentRefinement + 2}
                              </a>
                            </li>
                            ${currentRefinement < (nbPages - 3) ? `...` : ''} 
                               ${currentRefinement < (nbPages - 2) ? `
                        <li class="pagination__item">
                                <a href="${createURL(nbPages - 1)}" data-value="${nbPages - 1}" >
                                  ${nbPages}
                                </a>
                            </li> 
                        ` : ''}
                            
                            <a class="pagination__item pagination__item--next" href="${createURL(currentRefinement + 1)}" data-value="${currentRefinement + 1}" >
                                <i class="icon icon-angle-right"></i>
                            </a> 
                ` : ''}
            </ul>
          
            `;

            [...container.querySelectorAll('a')].forEach(element => {
                element.addEventListener('click', event => {
                    window.scrollTo({top: document.getElementById('userSearch').getBoundingClientRect().top + window.pageYOffset - document.querySelector('.headerMain').offsetHeight});
                    event.preventDefault();
                    refine(event.currentTarget.dataset.value);

                });
            });
            }else{
                container.innerHTML = ``;
            }
        });
    };
    const customPaginationContent = connectPagination(renderPaginationContent);


    let hitsPerPage = 18;

    let noResults = GLOBALS.tc.noResult;
    let noResults_1 = GLOBALS.tc.noResult_1;
    let noResults_2 = GLOBALS.tc.noResult_2;
    let showMore = GLOBALS.tc.showMore;
    let showLess = GLOBALS.tc.showLess;
    let foundItems = GLOBALS.tc.foundItems;
    let itemNo = GLOBALS.tc.itemNo;
    let itemDescription = GLOBALS.tc.itemDescription;
    let itemLatest = GLOBALS.tc.itemLatest;

    var numberFormatter = new Intl.NumberFormat(localeCode, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        trailingZeroDisplay: 'stripIfInteger'
    });
    if (searchbox && !categoryToShow) {

        search.addWidgets([

            searchBox({
                container: "#searchbox",
            }),
            configure({
                query: algoliaSearchTerm,
                hitsPerPage: hitsPerPage,
                maxValuesPerFacet: 1000,
            }),
            hits({
                container: "#algolia_hits",
                templates: {
                    empty(results  , { html }) {
                        for(let removeItem of document.querySelectorAll('.itemlist__header,.itemlist__description,.itemlist__footnode,.ais-InstantSearch .ais-InstantSearchFilter')){
                            removeItem.remove();
                        }
                        return html`<div class="gp hasNoLink marginBottom--large marginTop row">
                            <h2>${noResults_1}</h2>
                            <span>${noResults_2}</span>
                        </div>
                    <script>window.addEventListener("load", (event) => { lottieLoader(); });</script>`;


                    },
                    item: document.getElementById(ag_template).innerHTML
                },
            }),
            stats({
                container: '.noOfHits',
                templates: {
                    text(data, { html }) {
                        let count = '';

                        if (data.hasManyResults) {
                            count += `${data.nbHits}`;
                        } else if (data.hasOneResult) {
                            count += `${data.nbHits}`;
                        } else {
                            count += '0'; // Zeige 0 an, wenn keine Ergebnisse vorhanden sind
                        }

                        return html`<span>${count}</span>`;
                    },
                },
            }),
            clearRefinements({
                container: '#clear-refinements',
                cssClasses: {
                    button:'button--link'
                },
                templates: {
                    resetLabel({hasRefinements}, {html}) {
                        let removeFilter = GLOBALS.tc.removeFilter;
                        return html`<span>${removeFilter}</span>`;
                    },
                },
            }),

            customPagination({
                container: '.pagination-container',
            }),


            index({
                indexName: secondaryIndexName,
                indexId: 'instant_search_two',
            }).addWidgets([
                configure({
                    query: algoliaSearchTerm,
                    hitsPerPage: 20,
                    maxValuesPerFacet: 1000,
                    filters: '', // Keine Filter setzen
                }),
                hits({
                    container: '#algolia_hits_secondary',
                    templates: {
                        item: function(hit) {
                            // Wenn ein Highlight für title oder content vorhanden ist, benutze es.
                            const highlightedTitle = hit._highlightResult.title ? hit._highlightResult.title.value : hit.title;
                            const highlightedContent = hit._highlightResult.content ? hit._highlightResult.content.value : hit.content;

                            return `
                                <a href="${hit.url}" class="searchResults__contentItem marginBottom--xsmall">
                                    <div class="searchResults__contentItem--contentWrapper">
                                        <div class="searchResults__contentItem--image d-none d-sm-block">
                                            <img src="${hit.image?.imageUrl || '/userdata/images/logo/logo_notext.svg'}" alt=""/>
                                        </div>
                                        <div>
                                            <div class="searchResults__contentItem--headline">${highlightedTitle}</div>
                                            <div class="searchResults__contentItem--text">${highlightedContent}</div>
                                        </div>
                                    </div>
                                    <div class="button button--linkWithIcon"></div>
                                </a>
                            `;
                        },
                        empty(results, { html }) {
                            return html`
                                <div class="gp hasNoLink marginBottom--large marginTop row">
                                    <h2>${noResults_1}</h2>
                                    <span>${noResults_2}</span>
                                </div>
                            `;
                        },
                    },
                }),
                stats({
                    container: '.noOfHitsContent',
                    templates: {
                        text(data, { html }) {
                            return html`<span>${data.nbHits}</span>`;
                        },
                    },
                }),

                customPaginationContent({
                    container: '.pagination-container-content',
                }),
            ]),



            // (refinementList)({
            //     container: '#thema_subject-list',
            //     attribute: 'Thema subject category',
            //     showMore: true,
            //     showMoreLimit: 1000,
            //     limit: 5,
            //     searchable: true,
            //     searchablePlaceholder: GLOBALS.tc.filterSearch,
            //     sortBy: compareNumbers,
            //     templates: {
            //         empty(results, { html }) {
            //             return html`${noResults} <q>${results.query}</q>`;
            //         },
            //         item(item, {html}) {
            //             const {url, label, count, isRefined} = item;
            //
            //             return html`
            //                 <label class="ais-RefinementList-label">
            //                 <input type="checkbox" class="ais-RefinementList-checkbox" checked="${isRefined ? 'true' : ''}"  value="${label}"/>
            //                 <span class="ais-RefinementList-labelText">${numberFormatter.format(label,)}</span>
            //                 <span class="ais-RefinementList-count">${count}</span>
            //                 </label>
            //             `;
            //         },
            //         showMoreText(data, { html }) {
            //             return html`<span>${data.isShowingMore ? `${showLess}` : `${showMore} `}</span>`;},
            //     },
            // }),



        ]);

        refinementListArray.forEach((refinement) => {
            getFilterWidgets(search,refinement.description,
                'attributes.'+refinement.description, refinement.multi, 5,refinement.name,refinement.displayType);
        });

        search.start();
    }
    if (searchbox && categoryToShow) {
        search.addWidgets([
            configure({
                filters: "categoryPageId: '" + categoryToShow + "'",
                hitsPerPage: hitsPerPage,
                maxValuesPerFacet: 1000,
            }),
            searchBox({
                container: "#searchbox",
                placeholder: GLOBALS.tc.filterSearchPlaceholder,
                cssClasses: {
                    root: 'MyCustomSearchBox ',
                    form: [
                        'inputGroup inputGroup--infieldAddon',
                        'itemSearch--input',
                    ],
                    submitIcon: 'icon icon-search',
                },
            }),
            hits({
                container: "#algolia_hits",
                templates: {
                    empty(results, { html }) {
                        for(let removeItem of document.querySelectorAll('.itemlist__header,.itemlist__description,.itemlist__footnode,.ais-InstantSearch .ais-InstantSearchFilter')){
                            removeItem.remove();
                        }
                        //return html`${noResults} <q>${results.query}</q>`;
                        return html`
                            <div class="gp hasNoLink marginBottom--large row align-items-flex-start marginTop--large">
                                <div class="gp hasNoLink col-12 col-sm-8">
                                    <div class="gp hasNoLink  row align-items-center">
                                        <div class="col-12 col-sm-8">
                                            <div class="textcontent noBackground">
                                                <strong>Es sind noch keine Produkte in dieser Kategorie vorhanden. Schauen Sie doch demnächst hier wieder vorbei.</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>`;

                    },
                    item: document.getElementById(ag_template).innerHTML

                },
            }),
            clearRefinements({
                container: '#clear-refinements',
                templates: {
                    resetLabel({hasRefinements}, {html}) {
                        let removeFilter = GLOBALS.tc.removeFilter;
                        return html`<span>${removeFilter}</span>`;
                    },
                },
            }),

            customPagination({
                container: '.pagination-container',
            }),
            stats({
                container: '.noOfHits',
                templates: {
                    text(data, { html }) {
                        let count = '';

                        if (data.hasManyResults) {
                            count += `${data.nbHits} ${foundItems}`;
                        } else if (data.hasOneResult) {
                            count += ``;
                        } else {
                            count += ``;
                        }

                        return html`<span>${count}</span>`;
                    },
                },
            }),
            sortBy({
                container: '#sort-by-container',
                items: [
                    { value: algoliaIndexName, label: itemNo },
                    { value: algoliaIndexName.concat('_description') , label: itemDescription },
                    { value: algoliaIndexName.concat('_creation_date') ,    label: itemLatest }
                ],
                cssClasses: {
                    root: 'algolia__sortBy selectBody',
                    select: [
                        'MyCustomSortBySelect',
                        'MyCustomSortBySelect--subclass',
                    ],
                },
            })

        ]);


        refinementListArray.forEach((refinement) => {
            getFilterWidgets(search,refinement.description,
                'attributes.'+refinement.description, refinement.multi, 5,refinement.name,refinement.displayType);
        });

        search.start();
    }

    // itemlistViewSwitch();

    const placeholder = document.querySelector('.algoliaSearchWrapper.placeholder');
    if (placeholder) {
        placeholder.style.setProperty('display', 'none', 'important');
    }


    if (!showMoreButtonClicked) {
        hideFilters();
    }

    updateProductCount();


    setTimeout(() => {
        const noSearchResults = document.querySelector('.ais-Hits--empty');
        if (noSearchResults) {
            //lottieLoader();
        }
    }, 500);

}


function getFilterWidgets(search, container_name, attribute, isMultiple, itemsToDisplay,name,displayType) {

    if( displayType==0 || displayType==2 || displayType==4){
        getRefinementList(search,container_name, attribute, isMultiple, itemsToDisplay, name);
    }
    if(displayType==3){
        getSliderFilter(search,container_name, attribute, isMultiple, itemsToDisplay, name);
    }
    if(displayType==1){
        getRatingFilter(search,container_name, attribute, isMultiple, itemsToDisplay, name);
    }
    /*
    if(displayType==2 ){
        getMenuSelectFilter(search,container_name, attribute, isMultiple, itemsToDisplay, name);
        // getRefinementList(search,container_name, attribute, isMultiple, itemsToDisplay,name);
    }
    if(displayType==1 || displayType==0 ){
        getMenuListFilter(search,container_name, attribute, isMultiple, itemsToDisplay, name);
        //  getRefinementList(search,container_name, attribute, isMultiple, itemsToDisplay,name);
    } */
}

function getRefinementList(search, container_name, attribute, isMultiple, itemsToDisplay,name) {
    let noResults = GLOBALS.tc.noResult;
    let showMore = GLOBALS.tc.showMore;
    let showLess = GLOBALS.tc.showLess;

    const customCurrentRefinements = getCustomCurrentRefinements();


    return search.addWidgets([
        (refinementList({
                container: '.' + container_name + '-list',
                attribute: attribute,
                operator: (isMultiple== '1' ? 'or' : 'and'),
                limit: 99,
                showMore: true,
                showMoreLimit: 100,
                searchable: false,
                searchablePlaceholder: GLOBALS.tc.articleSearch,
                cssClasses: {
                    showMore: 'hidden'
                },
                sortBy: ['count:desc', 'name:asc'],
                templates: {
                    showMoreText(data, {html}) {
                        return html`<span>${data.isShowingMore ? `${showLess}` : `${showMore} `} </span>`;
                    },

                    item(item, {html}) {
                        const {url, label, count, isRefined} = item;

                        return html`
                            <label class="ais-RefinementList-label specialcheckbox" for="checkbox_${label}">
                                <input type="checkbox" id="checkbox_${label}" class="ais-RefinementList-checkbox" checked="${isRefined ? 'true' : ''}"  value="${label}"/>
                                ${label} (${count})
                                <i for="checkbox_${label}"></i>
                            </label>
                        `;
                    },

                    searchableNoResults(data) {
                        return `<span>${noResults}</span>`;
                    },
                }
            })
        ),
        customCurrentRefinements({
            container: '#current-refinements',
            cssClasses: {
                item: 'tag',
                list: 'tags d-none'
            },
        })
    ]);
}

function getCustomCurrentRefinements(){
    const createDataAttribtues = refinement =>
        Object.keys(refinement)
            .map(key => `data-${key}="${refinement[key]}"`)
            .join(' ');

    function generateRenderListItems(items){
        let html = "";
        for(let item of items){
            for(let refinement of item.refinements){
                let value = refinement.value;
                if(!isNaN(parseFloat(refinement.value))){
                    value = parseFloat(refinement.value);
                }
                let label = refinement.attribute;
                if(GLOBALS.tc[refinement.attribute.toLowerCase()]){
                    label = GLOBALS.tc[refinement.attribute.toLowerCase()];
                }
                if(refinementListArray){
                    let obj = refinementListArray.find(o => o.code === refinement.attribute);
                    if(obj){
                        label = obj.name;
                    }
                }

                function formatRefinementLabel(input) {
                    let formatted = input.replace("attributes.", "");

                    formatted = formatted.replace(/_/g, " ");

                    formatted = formatted.toLowerCase().split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");

                    return formatted;
                }

                html += `<li class="ais-CurrentRefinements-item tag" ${createDataAttribtues(refinement)}>${formatRefinementLabel(label)}: ${value}</li>`;
            }
        }

        return html;
    }

    const renderCurrentRefinements = (renderOptions) => {
        const { items, refine, widgetParams } = renderOptions;
        const container = document.querySelector(widgetParams.container);

        if(container){
            container.innerHTML = `<ul class="ais-CurrentRefinements-list tags">
              ${generateRenderListItems(items)}
            </ul>`;

            for(let element of container.querySelectorAll('.ais-CurrentRefinements-item')){
                element.addEventListener('click', event => {
                    const item = Object.keys(event.currentTarget.dataset).reduce(
                        (acc, key) => ({
                            ...acc,
                            [key]: event.currentTarget.dataset[key],
                        }),
                        {}
                    );

                    refine(item);
                });
            }
        }
    };

    return connectCurrentRefinements(
        renderCurrentRefinements
    );
}


function getSliderFilter(search, container_name, attribute, isMultiple, itemsToDisplay,name) {

    let gap = 1;

    if(container_name == 'PRICE' ){
        attribute = 'itemPrice';
        gap = 5;
    }

    if( container_name == 'DISCOUNT'){
        attribute = 'discount';
        gap = 1;

    }
    const renderRangeSlider = (renderOptions, isFirstRender) => {
        const {start, range, refine, widgetParams} = renderOptions;
        const [min, max] = start;
        if (document.querySelector('.' + container_name + '-list')) {
            if (isFirstRender || !document.querySelector('.' + container_name + '-list').querySelector('.SliderMin')) {
                const rangeDiv = document.createElement('div');
                rangeDiv.classList.add('ais-RangeSlider');

                const form = document.createElement('form');
                form.classList.add('input-slider-form');

                const sliderDiv = document.createElement('div');
                sliderDiv.classList.add('slider-container');
                sliderDiv.classList.add('range-slider');

                const inputSliderMin = document.createElement('input');
                const inputSliderMax = document.createElement('input');
                inputSliderMin.type = 'range';
                inputSliderMax.type = 'range';
                inputSliderMin.classList.add('SliderMin');
                inputSliderMax.classList.add('SliderMax');

                const inputFieldMin = document.createElement('input');
                const inputFieldMax = document.createElement('input');
                inputFieldMin.type = 'number';
                inputFieldMax.type = 'number';
                inputFieldMin.classList.add('inputFieldMin');
                inputFieldMax.classList.add('inputFieldMax');

                // Text zwischen den beiden Input-Feldern
                const dashText = document.createTextNode(' - ');

                // Währungssymbol
                const currencySymbolText = document.createTextNode('€');

                inputFieldMin.addEventListener('input', event => {
                    event.preventDefault();

                    let minVal = parseFloat(inputFieldMin.value);
                    let maxVal = parseFloat(inputFieldMax.value);

                    let diff = maxVal - minVal;

                    // Check if the  gap is exceeded
                    if (diff < gap) {
                        // Check if the input is the min range input
                        inputSliderMin.value = maxVal - gap;
                    } else {
                        // Update  inputs and range progress
                        inputSliderMin.value = minVal;
                        inputSliderMax.value = maxVal;
                        inputSliderMin.style.left =
                            `${(minVal / range.max) * 100}%`;
                        inputSliderMax.style.right =
                            `${100 - (maxVal / range.max) * 100}%`;
                    }

                    refine([
                        Number.isFinite(inputFieldMin) ? inputFieldMin : undefined,
                        Number.isFinite(inputFieldMax) ? inputFieldMax : undefined,
                    ]);
                });
                inputFieldMax.addEventListener('input', event => {
                    event.preventDefault();


                    let minVal = parseFloat(inputFieldMin.value);
                    let maxVal = parseFloat(inputFieldMax.value);

                    let diff = maxVal - minVal;

                    // Check if the  gap is exceeded
                    if (diff < gap) {
                        // Check if the input is the min range input
                        inputFieldMax.value = minVal + gap;
                    } else {
                        // Update  inputs and range progress
                        inputSliderMin.value = minVal;
                        inputSliderMax.value = maxVal;
                        inputSliderMin.style.left =
                            `${(minVal / range.max) * 100}%`;
                        inputSliderMax.style.right =
                            `${100 - (maxVal / range.max) * 100}%`;
                    }

                    refine([
                        Number.isFinite(inputFieldMin) ? inputFieldMin : undefined,
                        Number.isFinite(inputFieldMax) ? inputFieldMax : undefined,
                    ]);
                });

                inputSliderMin.addEventListener('change', event => {
                    let minVal =
                        parseInt(inputSliderMin.value);
                    let maxVal =
                        parseInt(inputSliderMax.value);

                    let diff = maxVal - minVal;

                    // Check if the  gap is exceeded
                    if (diff < gap) {
                        // Check if the input is the min range input
                        inputSliderMin.value = maxVal - gap;
                    } else {
                        // Update  inputs and range progress
                        inputFieldMin.value = minVal;
                        inputFieldMax.value = maxVal;
                        inputSliderMin.style.left =
                            `${(minVal / range.max) * 100}%`;
                        inputSliderMax.style.right =
                            `${100 - (maxVal / range.max) * 100}%`;
                    }


                    refine([parseFloat(inputSliderMin.value), parseFloat(inputSliderMax.value)]);
                });
                inputSliderMax.addEventListener('change', event => {
                    let minVal =
                        parseInt(inputSliderMin.value);
                    let maxVal =
                        parseInt(inputSliderMax.value);

                    let diff = maxVal - minVal;

                    // Check if the price gap is exceeded
                    if (diff < gap) {

                        // Check if the input is the min range input
                        inputSliderMax.value = minVal + gap;
                    } else {

                        // Update price inputs and range progress
                        inputFieldMin.value = minVal;
                        inputFieldMax.value = maxVal;
                        inputSliderMin.style.left =
                            `${(minVal / range.max) * 100}%`;
                        inputSliderMax.style.right =
                            `${100 - (maxVal / range.max) * 100}%`;
                    }
                    refine([parseFloat(inputSliderMin.value), parseFloat(inputSliderMax.value)]);
                });


                const submitButton = document.createElement('input');
                submitButton.type = 'submit';
                submitButton.classList.add('hidden');
                form.appendChild(inputFieldMin);
                form.appendChild(dashText);
                form.appendChild(inputFieldMax);
                form.appendChild(currencySymbolText);
                form.appendChild(submitButton);


                const minValueDisplay = document.createElement('span');
                const maxValueDisplay = document.createElement('span');
                minValueDisplay.classList.add('rangeSliderMin');
                maxValueDisplay.classList.add('rangeSliderMax');
                sliderDiv.appendChild(minValueDisplay);
                sliderDiv.appendChild(inputSliderMin);
                sliderDiv.appendChild(inputSliderMax);
                sliderDiv.appendChild(maxValueDisplay);

                rangeDiv.appendChild(form);
                rangeDiv.appendChild(sliderDiv);

                document.querySelector('.' + container_name + '-list').appendChild(rangeDiv);

                return;
            }


            const SliderMin = document.querySelector('.' + container_name + '-list').querySelector('.SliderMin');
            const SliderMax = document.querySelector('.' + container_name + '-list').querySelector('.SliderMax');
            const inputFieldMin = document.querySelector('.' + container_name + '-list').querySelector('.inputFieldMin');
            const inputFieldMax = document.querySelector('.' + container_name + '-list').querySelector('.inputFieldMax');
            const minValueDisplay = document.querySelector('.' + container_name + '-list').querySelector('.rangeSliderMin');
            const maxValueDisplay = document.querySelector('.' + container_name + '-list').querySelector('.rangeSliderMax');

            SliderMin.min = range.min;
            SliderMin.max = range.max;
            SliderMin.value = Number.isFinite(min) ? min : range.min;
            SliderMax.min = range.min;
            SliderMax.max = range.max;
            SliderMax.value = Number.isFinite(max) ? max : range.max;

            minValueDisplay.innerHTML = range.min;
            maxValueDisplay.innerHTML = range.max;

            inputFieldMin.placeholder = Number.isFinite(min) ? min : range.min;
            inputFieldMax.placeholder = Number.isFinite(max) ? max : range.max;
            inputFieldMin.value = Number.isFinite(min) ? min : range.min;
            inputFieldMax.value = Number.isFinite(max) ? max : range.max;

        }else {
            return;
        }


    };

// Create the custom widget
    const customRangeSlider = connectRange(
        renderRangeSlider
    );

// Instantiate the custom widget
    return  search.addWidgets([
        customRangeSlider({
            container: '.' + container_name + '-list',
            attribute: attribute,
        })
    ]);
}


function getMenuListFilter(search, container_name, attribute, isMultiple, itemsToDisplay, name){
    let noResults = GLOBALS.tc.noResult;
    let showMore = GLOBALS.tc.showMore;
    let showLess = GLOBALS.tc.showLess;
    let showAll = GLOBALS.tc.seeAll;

    return search.addWidgets([
        menu({
            container: '.' + container_name + '-list',
            attribute: attribute,
            limit: itemsToDisplay,
            showMore: true,
            showMoreLimit: 100,
            sortBy: ['count:desc', 'name:asc'],
            templates: {
                defaultOption(data, { html }) {
                    return html`<span>${showAll}</span>`;
                },
            },
        })
    ]);
}
function getMenuSelectFilter(search, container_name, attribute, isMultiple, itemsToDisplay, name){
    let noResults = GLOBALS.tc.noResult;
    let showMore = GLOBALS.tc.showMore;
    let showLess = GLOBALS.tc.showLess;
    return search.addWidgets([
        menuSelect({
            container: '.' + container_name + '-list',
            attribute: attribute,
            limit: itemsToDisplay,
            showMore: true,
            showMoreLimit: 100,
            sortBy: ['count:desc', 'name:asc'],
        })
    ]);
}
function getRatingFilter(search, container_name, attribute, isMultiple, itemsToDisplay, name){

    // Create the render function
    const renderRatingMenu = (renderOptions, isFirstRender) => {
        const {items, refine, createURL, widgetParams} = renderOptions;
        if (document.querySelector('.' + container_name + '-list')) {
            if (isFirstRender) {
                const ratingDiv = document.createElement('div');
                const rating = document.createElement('ul');
                ratingDiv.classList.add('ais-RatingMenu');
                ratingDiv.appendChild(rating);
                document.querySelector('.' + container_name + '-list').appendChild(ratingDiv);

                return;
            }

            document.querySelector('.' + container_name + '-list').querySelector('ul').innerHTML = items
                .map(
                    item =>
                        `<li>
          <a
            href="#"
            data-value="${item.value}"
            style="font-weight: ${item.isRefined ? 'bold' : ''}"
          ><span class="from">ab </span>
            ${item.stars.map(isFilled => (isFilled ? '<i class="icon icon-star-filled" aria-hidden="true"></i>' : '<i class="icon icon-star" aria-hidden="true"></i>')).join('')}
            <span>(${item.count})</span>
          </a>
        </li>`
                )
                .join('');

            [...document.querySelector('.' + container_name + '-list').querySelectorAll('a')].forEach(element => {
                element.addEventListener('click', event => {
                    event.preventDefault();
                    refine(event.currentTarget.dataset.value);
                });
            });
        } else {
            return;
        }
    };

// Create the custom widget
    const customRatingMenu = connectRatingMenu(
        renderRatingMenu
    );

// Instantiate the custom widget
    return search.addWidgets([
        customRatingMenu({
            container: '.' + container_name + '-list',
            attribute: 'avgRating',
        })
    ]);
}

export function algoliaListUpdate() {
    let currentURL = window.location.pathname;
    let item_list = [];
    let iteratorCount = 0;
    const ajax_price_elements = document.querySelectorAll(".js_ajax_price");
    for (const itemElement of ajax_price_elements) {
        let itemNumber = itemElement.dataset.itemNo;
        let itemId = itemElement.dataset.itemId;
        item_list.push({'itemNo':itemNumber,'itemId': itemId});
    }
    if (item_list.length > 0) {
        XhrService.timeout = 20000;
        XhrService.generalAjaxCall('getAlgoliaItemListRender', {"item_list": item_list,'counter' :iteratorCount}, 'POST', 'json').then(response => {
            if (response) {
                const response_item_key = Object.keys(response);
                for (const item_id of response_item_key) {
                    const itemElement = document.getElementById(item_id);
                    if (itemElement) {
                        if (response[item_id].prices) {
                            itemElement.querySelector('.js_ajax_price').innerHTML = response[item_id].prices;
                        }
                        if (response[item_id].rating) {
                            itemElement.querySelector('.itembox__rating').innerHTML = response[item_id].rating;
                        }
                        if (response[item_id].favoriteSign) {
                            itemElement.querySelector('.favoriteWrapper').innerHTML = response[item_id].favoriteSign;
                        }
                        if (response[item_id].promotions) {
                            itemElement.querySelector('.promotions').innerHTML = response[item_id].promotions;
                        }

                        if (response[item_id].orderbutton) {
                            itemElement.querySelector('.inventoryWrapper').innerHTML = response[item_id].orderbutton;
                        }
                        if (response[item_id].priceText) {
                            itemElement.querySelector('.priceNotice').innerHTML = response[item_id].priceText;
                        }

                        if (response[item_id].compareSign) {
                            itemElement.querySelector('.compareWrapper').innerHTML = response[item_id].compareSign;
                        }
                    }

                }
            }

        });
        iteratorCount++;
    }
    if (item_list.length > 0) {
        XhrService.timeout = 20000;
        XhrService.generalAjaxCall('getDataLayerForAlgolia', {"itemArray": item_list, 'currentURL' :currentURL}, 'POST', 'json').then(response => {
            if (response) {
                if (response.script) {
                    var datalayerPushFunction = new Function(response.script);
                    datalayerPushFunction ();
                }
                if (response.tag) {
                    document.querySelector('.tagManagerDiv').innerHTML = response.tag;
                }
            }
        });
    }


    for (let el of document.querySelectorAll('.ais-Hits-item')) {
        hoverintent(el);
    }


    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]'));
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl);
    });

    updateTextBasedOnDataAttributeAndOperator();
    updateRatingText();
    updateDiscountText();


}

export function toggleAlgoliaSearch() {
    const searchMobileButton = document.querySelector('.searchMobile');
    const algoliaSearchWrapper = document.querySelector('.algoliaSearchWrapper');

    if (searchMobileButton && algoliaSearchWrapper) {
        searchMobileButton.addEventListener('click', function() {
            algoliaSearchWrapper.classList.toggle('is-active');
        });

        document.addEventListener('click', function(event) {
            const isClickInside = algoliaSearchWrapper.contains(event.target) || searchMobileButton.contains(event.target);

            if (!isClickInside) {
                algoliaSearchWrapper.classList.remove('is-active');
            }
        });
    }
}

export function setupAlgoliaFilterWrappers() {
    const algoliaFilterWrappers = document.querySelectorAll('.algoliaFilterWrapper');

    document.addEventListener('click', function (event) {
        const clickedRefinementList = event.target.closest('.ais-RefinementList');
        const clickedRefinementListItem = event.target.closest('.ais-RefinementList-item');

        if (!clickedRefinementList || !clickedRefinementListItem) {
            algoliaFilterWrappers.forEach(function (algoliaFilterWrapper) {
                algoliaFilterWrapper.classList.remove('is-active');
            });
        }
    });

    algoliaFilterWrappers.forEach(function (algoliaFilterWrapper) {
        algoliaFilterWrapper.addEventListener('click', function (event) {
            const isInsideRefinementList = event.target.closest('.ais-RefinementList');

            event.stopPropagation();

            if (!isInsideRefinementList) {
                // Überprüfen, ob bereits eine "is-active"-Klasse vorhanden ist und sie entfernen
                algoliaFilterWrappers.forEach(function (otherFilterWrapper) {
                    if (otherFilterWrapper !== algoliaFilterWrapper) {
                        otherFilterWrapper.classList.remove('is-active');
                    }
                });

                algoliaFilterWrapper.classList.add('is-active');
            }
        });

        const closeFilterButton = algoliaFilterWrapper.querySelector('.closeFilter');
        if (closeFilterButton) {
            closeFilterButton.addEventListener('click', function (event) {
                event.stopPropagation();
                algoliaFilterWrapper.classList.remove('is-active');
            });
        }
    });
}



function itemlistViewSwitch() {
    let hitsContainer = document.querySelector('#algolia_hits');
    let viewSwitchButtonContainer = document.querySelector('.itemlistViewSwitch');
    let itemListView = document.querySelector('.itemListView');

    if (hitsContainer && viewSwitchButtonContainer && itemListView) {
        let buttonTiles = viewSwitchButtonContainer.querySelector('.icon-grid-kacheln');
        let buttonList = viewSwitchButtonContainer.querySelector('.icon-grid-liste');

        if (display == 'tile') {
            buttonTiles.classList.add('active');
            itemListView.classList.add('tiles');
        } else {
            buttonList.classList.add('active');
            itemListView.classList.add('list');
        }

        buttonTiles.addEventListener('click', function () {
            let display_type = 'tile';
            sessionStorage.setItem('display_type', JSON.stringify(display_type));
            location.reload();
        });

        buttonList.addEventListener('click', function () {
            let display_type = 'list';
            sessionStorage.setItem('display_type', JSON.stringify(display_type));
            location.reload();
        });
    }
}
function compareNumbers(a, b) {
    return parseInt(a.name, 10) - parseInt(b.name, 10);
}

function showSearchBars(){
    for(let searchInput of document.querySelectorAll('.ais-SearchBox-input')){
        let refinement = searchInput.closest(".ais-RefinementList");
        let searchBox = searchInput.closest(".ais-RefinementList-searchBox");

        if(refinement){
            let refinementItems = refinement.querySelectorAll('.ais-RefinementList-item');
            if(refinementItems){
                if(refinementItems.length > 4){
                    searchInput.disabled = false;

                }else{
                    searchInput.disabled = true;
                    searchBox.style.display = 'none';
                }
            }
        }
    }
}

// Export the function
export function updateSelectedFilters(wrapper) {
    if (!wrapper) {
        return; // Skip the update if the wrapper is undefined
    }

    // Suche nach allen Elementen mit der Klasse "ais-RefinementList-item--selected" innerhalb des aktuellen algoliaFilterWrapper
    const selectedItems = wrapper.querySelectorAll('.ais-RefinementList-item--selected');

    // Zähle die ausgewählten Elemente
    const count = selectedItems.length;

    // Aktualisiere die Anzeige innerhalb des aktuellen "algoliaFilterWrapper"
    const selectedFiltersElement = wrapper.querySelector('.selectedFilters');
    if (selectedFiltersElement) {
        // Überprüfe, ob die Anzahl größer als 0 ist, bevor sie in das Element geschrieben wird
        if (count > 0) {
            selectedFiltersElement.textContent = count;
        } else {
            selectedFiltersElement.textContent = ''; // Wenn der Wert 0 ist, entferne den Text
        }
    }
}

function showOrHideFilters() {
    var showFacetSelector = '.ais-RefinementList-list';
    var showFacets = document.querySelectorAll(showFacetSelector);
    showFacets.forEach(function (elem) {
        elem.parentNode.parentNode.parentNode.style.display = 'block';
        elem.parentNode.parentNode.parentNode.classList.remove("hidden");
    });

    var showMenuFacetSelector = '.ais-Menu-list';
    var showMenuFacets = document.querySelectorAll(showMenuFacetSelector);
    showMenuFacets.forEach(function (elem) {
        elem.parentNode.parentNode.parentNode.style.display = 'block';
        elem.parentNode.parentNode.parentNode.classList.remove("hidden");

    });

    var showMenuSelectFacetSelector = '.ais-MenuSelect-select';
    var showMenuSelectFacets = document.querySelectorAll(showMenuSelectFacetSelector);
    showMenuSelectFacets.forEach(function (elem) {
        elem.parentNode.parentNode.parentNode.style.display = 'block';
        elem.parentNode.parentNode.parentNode.classList.remove("hidden");

    });

    var showRangeSliderFacetSelector = '.ais-RangeSlider';
    var showRangeSliderFacets = document.querySelectorAll(showRangeSliderFacetSelector);
    showRangeSliderFacets.forEach(function (elem) {
        elem.parentNode.parentNode.style.display = 'block';
        elem.parentNode.parentNode.classList.remove("hidden");

    });

    var showRatingFacetSelector = '.ais-RatingMenu';
    var showRatingFacets = document.querySelectorAll(showRatingFacetSelector);
    showRatingFacets.forEach(function (elem) {
        elem.parentNode.parentNode.style.display = 'block';
        elem.parentNode.parentNode.classList.remove("hidden");

    });

    var emptyListFacetSelector = '.ais-RefinementList--noRefinement';
    var emptyListFacets = document.querySelectorAll(emptyListFacetSelector);
    emptyListFacets.forEach(function (elem) {
        elem.parentNode.parentNode.style.display = 'none';
    });

    var emptyMenuFacetSelector = '.ais-Menu--noRefinement';
    var emptyMenuFacets = document.querySelectorAll(emptyMenuFacetSelector);
    emptyMenuFacets.forEach(function (elem) {
        elem.parentNode.parentNode.style.display = 'none';
    });

    var emptyMenuSelectFacetSelector = '.ais-MenuSelect--noRefinement';
    var emptyMenuSelectFacets = document.querySelectorAll(emptyMenuSelectFacetSelector);
    emptyMenuSelectFacets.forEach(function (elem) {
        elem.parentNode.parentNode.style.display = 'none';
    });

    var emptyRangeSliderFacets = '.SliderMin';
    var rangeSliderFacets = document.querySelectorAll(emptyRangeSliderFacets);
    rangeSliderFacets.forEach(function (elem) {
        if(elem.min == elem.max){
            elem.parentNode.parentNode.parentNode.parentNode.style.display = 'none';
        }
    });

    var emptyRatingFacets = '.ais-RatingMenu';
    var ratingFacets = document.querySelectorAll(emptyRatingFacets);
    ratingFacets.forEach(function (elem) {
        if (!elem.querySelector('ul').querySelector('li')) {
            elem.parentNode.parentNode.style.display = 'none';
        }
    });

}
function updateRobotContent()
{
    history.pushState = (f => function pushState() {
        var ret = f.apply(this, arguments);
        window.dispatchEvent(new Event('pushstate'));
        window.dispatchEvent(new Event('locationchange'));
        return ret;
    })(history.pushState);
    history.replaceState = (f => function replaceState() {
        var ret = f.apply(this, arguments);
        window.dispatchEvent(new Event('replacestate'));
        window.dispatchEvent(new Event('locationchange'));
        return ret;
    })(history.replaceState);
    window.addEventListener('locationchange', function () {
        changeRoboTextContent();
    });
    changeRoboTextContent();
}

function changeRoboTextContent()
{
    let currentURLParams = window.location.search;
    if (currentURLParams.length > 1) {
        if(document.querySelector('meta[name=robots]')){
            document.querySelector('meta[name=robots]').content = "noindex,follow";
            const element = document.querySelector('link[rel=canonical]');
            if(element){
                element.remove();
            }
        }
    }
}

// Funktion aufrufen
function startUpdatingFilters() {
    // Starte die Aktualisierung für jedes "algoliaFilterWrapper"
    const algoliaFilterWrappers = document.querySelectorAll('.algoliaFilterWrapper');
    algoliaFilterWrappers.forEach(wrapper => {
        setInterval(() => updateSelectedFilters(wrapper), 1000); // Passen Sie die Intervallzeit nach Bedarf an
    });
}

// Aufruf der Funktion
startUpdatingFilters();

// Funktion, um Text basierend auf Data-Attribute und Operator zu ändern
function updateTextBasedOnDataAttributeAndOperator() {
    // Alle Elemente mit der Klasse "ais-CurrentRefinements-item" auswählen
    const refinementItems = document.querySelectorAll('.ais-CurrentRefinements-item');

    // Schleife durch jedes Element
    refinementItems.forEach(item => {
        // Überprüfen, ob das Data-Attribute "itemPrice" ist
        if (item.dataset.attribute === 'itemPrice') {
            // Überprüfen, welcher Operator verwendet wird
            const operator = item.dataset.operator;
            let operatorText = '';

            // Entsprechenden Operator-Text setzen
            if (operator === '>=') {
                operatorText = ' ab';
            } else if (operator === '<=') {
                operatorText = ' bis';
            }

            // Den Text des Elements aktualisieren, um den neuen Wert und Operator-Text zu reflektieren
            item.textContent = 'Preis:' + operatorText + ' ' + item.dataset.value + ' €';
        }
    });
}

function updateDiscountText() {
    // Alle Elemente mit der Klasse "ais-CurrentRefinements-item" auswählen
    const refinementItems = document.querySelectorAll('.ais-CurrentRefinements-item');

    // Schleife durch jedes Element
    refinementItems.forEach(item => {
        // Überprüfen, ob das Data-Attribute "itemPrice" ist
        if (item.dataset.attribute === 'discount') {
            // Überprüfen, welcher Operator verwendet wird
            const operator = item.dataset.operator;
            let operatorText = '';

            // Entsprechenden Operator-Text setzen
            if (operator === '>=') {
                operatorText = ' von';
            } else if (operator === '<=') {
                operatorText = ' bis';
            }

            // Den Text des Elements aktualisieren, um den neuen Wert und Operator-Text zu reflektieren
            item.textContent = 'Rabatt:' + operatorText + ' ' + item.dataset.value + ' €';
        }
    });
}

function updateRatingText() {
    const refinementsList = document.querySelectorAll('#current-refinements .ais-CurrentRefinements-item');

    refinementsList.forEach(item => {
        const dataAttribute = item.getAttribute('data-attribute');

        if (dataAttribute === 'avgRating') {
            // Ersetze 'Avgrating' durch 'Bewertung:'
            const labelText = item.getAttribute('data-value').replace('Avgrating', 'Bewertung:');

            // Füge 'ab' oder 'bis' hinzu, basierend auf dem Operator
            const operatorText = item.getAttribute('data-operator') === '>=' ? 'ab' : item.getAttribute('data-operator') === '<=' ? 'bis' : '';
            const newText = operatorText + ' ' + labelText;

            // Setze den neuen Text
            item.textContent = newText + ' Sterne';

            if (item.getAttribute('data-operator') === '<=') {
                item.classList.add('hidden');
            } else {
                item.classList.remove('hidden');
            }
        }
    });
}




document.querySelectorAll('.filter__close').forEach(function(closeButton) {
    closeButton.addEventListener('click', function() {
        var filterBox = this.closest('.filterBox');
        if (filterBox) {
            filterBox.classList.remove('is-active');
            fadeOut(overlay);
            document.body.style.overflow = '';
        }
    });
});


function addCloseButtonToFilterBody() {
    if (window.innerWidth < 768) {
        document.querySelectorAll('.filterBody').forEach(function (filterBody) {
            if (filterBody.querySelector('.refinement-filter__close')) {
                return;
            }

            var closeDiv = document.createElement('div');
            closeDiv.className = 'refinement-filter__close d-none';

            var icon = document.createElement('i');
            icon.className = 'icon icon-close';

            closeDiv.appendChild(icon);

            filterBody.insertBefore(closeDiv, filterBody.firstChild);
        });

        document.querySelectorAll('.filterBody').forEach(function (filterBody) {
            filterBody.addEventListener('click', function (event) {
                if (event.target.matches('.refinement-filter__close, .refinement-filter__close *')) {
                    event.stopPropagation();
                    var filterBox = this.closest('.algoliaFilterWrapper');
                    if (filterBox) {
                        filterBox.classList.remove('is-active');
                    }
                }
            });
        });
    }
}

let showMoreButton;

let showLessButton;

let showMoreButtonClicked;

function hideFilters() {
    const filterWrappers = Array.from(document.querySelectorAll('.algoliaFilterWrapper'));
    const windowWidth = window.innerWidth;
    let maxVisible = windowWidth < 1250 ? 9 : 10;

    if(document.querySelector(".searchResults")){
        maxVisible++;
    }

    const visibleFilterWrappers = filterWrappers.filter(wrapper =>
        getComputedStyle(wrapper).display === 'block'
    );

    if (visibleFilterWrappers.length > maxVisible + 1 && visibleFilterWrappers.length !== 0) {
        if(showMoreButton && window.getComputedStyle(showMoreButton).display !== "none"){
            visibleFilterWrappers.forEach((wrapper, index) => {
                if (index >= maxVisible) {
                    wrapper.classList.add("hidden");
                }
            });
        }

        if (!showMoreButton) {
            showMoreButton = document.createElement('button');
            showMoreButton.className = 'ais-ClearRefinements-button button--link';
            showMoreButton.innerText = '+ weitere Filter';

            showMoreButton.addEventListener('click', function () {
                showMoreButtonClicked = true;
                visibleFilterWrappers.forEach(wrapper => {
                    wrapper.classList.remove("hidden");
                });
                this.style.display = 'none';
                if (showLessButton) {
                    showLessButton.style.display = 'block';
                }
            });

            const lastFilterWrapper = filterWrappers[filterWrappers.length - 1];
            lastFilterWrapper.parentNode.insertBefore(showMoreButton, lastFilterWrapper.nextSibling);
        }

        if (!showLessButton) {
            showLessButton = document.createElement('button');
            showLessButton.className = 'ais-ClearRefinements-button button--link';
            showLessButton.innerText = '- weniger Filter';

            showLessButton.addEventListener('click', function () {
                visibleFilterWrappers.forEach((wrapper, index) => {
                    if (index >= maxVisible) {
                        wrapper.classList.add("hidden");
                    }
                });
                this.style.display = 'none';
                if (showMoreButton) {
                    showMoreButton.style.display = 'block';
                }
            });

            showLessButton.style.display = 'none';

            const lastFilterWrapper = filterWrappers[filterWrappers.length - 1];
            lastFilterWrapper.parentNode.insertBefore(showLessButton, lastFilterWrapper.nextSibling);
        }
    }
}


addCloseButtonToFilterBody();

const observerCallback = (mutationsList, observer) => {
    mutationsList.forEach((mutation) => {
        if ((mutation.type === 'childList' && mutation.addedNodes.length > 0) || mutation.type === 'attributes') {
            hideFilters();
            addCloseButtonToFilterBody();
        }
    });

    if (showMoreButton && showMoreButton.style.display === 'none') {
        observer.disconnect();
    }
};

const targetNodes = document.querySelectorAll('.filterBox');
targetNodes.forEach(targetNode => {
    const observer = new MutationObserver(observerCallback);
    observer.observe(targetNode, { childList: true, subtree: true, attributes: true, attributeFilter: ['style'] });
});

const algoliaFilterWrappers = document.querySelectorAll('.algoliaFilterWrapper');
algoliaFilterWrappers.forEach(filterWrapper => {
    const classObserver = new MutationObserver(observerCallback);
    classObserver.observe(filterWrapper, { attributes: true });
});

// lottieLoader();

function updateProductCount() {
    var statsText = document.querySelector('.ais-Stats-text span');
    if (statsText) {
        var number = statsText.textContent.trim();
        var productCountSpan = document.querySelector('.product_count');
        if (productCountSpan) {
            productCountSpan.textContent = number;
        }
    }
}

document.querySelectorAll('.filterBox__showResults .button').forEach(function(showResultsButton) {
    showResultsButton.addEventListener('click', function() {
        var filterBox = this.closest('.filterBox');
        if (filterBox) {
            filterBox.classList.remove('is-active');
            fadeOut(overlay);
            document.body.style.overflow = '';
        }
    });
});

// Definiere die Funktion
export function toggleAlgoliaSearchNew() {
    // Finde alle Elemente mit der Klasse "js-toggleAlgoliaSearch"
    const toggleElements = document.querySelectorAll('.js-toggleAlgoliaSearch');

    // Iteriere durch jedes gefundene Element
    toggleElements.forEach(element => {
        // Füge einen Klick-Eventlistener hinzu
        element.addEventListener('click', (event) => {
            // Verhindere, dass das Klick-Ereignis die Seite weiter beeinflusst
            event.stopPropagation();

            // Finde das nächste Element mit der Klasse "aa-notVisible"
            const notVisibleElement = element.nextElementSibling.closest('.aa-notVisible');

            document.querySelector('.aa-Input').focus();

            // Wenn ein nicht sichtbares Element gefunden wurde
            if (notVisibleElement) {
                // Entferne die Klasse "aa-notVisible"
                notVisibleElement.classList.remove('aa-notVisible');
            }
        });
    });

    // Füge einen Klick-Eventlistener zum Dokument hinzu, um zu überprüfen, ob außerhalb der Klasse "algoliaSearchWrapper" geklickt wird
    document.addEventListener('click', (event) => {
        // Überprüfe, ob das geklickte Element innerhalb der Klasse "algoliaSearchWrapper" liegt
        const isClickedInsideAlgoliaSearchWrapper = event.target.closest('.algoliaSearchWrapper');

        // Wenn das geklickte Element nicht innerhalb der Klasse "algoliaSearchWrapper" liegt
        if (!isClickedInsideAlgoliaSearchWrapper) {
            // Finde alle Elemente mit der Klasse "aa-notVisible"
            const notVisibleElements = document.querySelectorAll('.algoliaSearchWrapper:not(.algoliaExtraSearchWrapper)');


            // Iteriere durch jedes gefundene Element
            notVisibleElements.forEach(element => {
                // Füge die Klasse "aa-notVisible" wieder hinzu
                element.classList.add('aa-notVisible');
            });
        }
    });
}

