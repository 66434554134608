import {autocomplete, getAlgoliaResults} from '@algolia/autocomplete-js';
import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import historyRouter from 'instantsearch.js/es/lib/routers/history';
import {connectSearchBox} from 'instantsearch.js/es/connectors';
import {configure, hierarchicalMenu, hits, pagination} from 'instantsearch.js/es/widgets';

import '@algolia/autocomplete-theme-classic';

export function algoliaDoAutoComplete() {
    document.querySelector('.js-closeSearchWrapper').addEventListener('click', function() {
        var searchWrapper = document.querySelector('.algoliaSearchWrapper');
        if (searchWrapper) {
            searchWrapper.classList.add('aa-notVisible');
        }
    });

    const searchClient = algoliasearch(algoliaAppId, algoliaSearchKey);
    let autoComplete = document.querySelector('.algoliaSearchWrapper');
    let extraSearchBox = document.querySelector('.algoliaExtraSearchWrapper');
    let orderNo = GLOBALS.tc.orderNo;

    if (autoComplete) {
        autocomplete({
            container: '.headerMain .autocomplete__search',
            panelContainer: '.headerMain .autocomplete__results',
            placeholder: searchPlaceHolder,
            detachedMediaQuery: 'none',
            onSubmit(params) {
                if (params.state.query) {
                    location.href = `/${customizedUrl}/search/?input_search=${params.state.query}`;
                }
            },
            getSources({query}) {
                return [
                    {
                        sourceId: 'products', // Quelle für Produkte
                        getItems() {
                            return getAlgoliaResults({
                                searchClient,
                                queries: [{
                                    indexName: algoliaIndexName, // Produktsuche
                                    query,
                                    params: {
                                        hitsPerPage: 5,
                                    },
                                }],
                            });
                        },
                        templates: {
                            header() {
                                    // Aktuelle URL abrufen
                                    var currentUrl = window.location.href;

                                    // Standard-Überschrift
                                    var headline = 'Produkte';

                                    // Überprüfen, ob die URL '/en/' enthält
                                    if (currentUrl.includes('/en/')) {
                                        headline = 'Products';
                                    }

                                    return headline;
                            },
                            item({item, components, html}) {
                                return html`
                                    <div class="aa-ItemWrapper">
                                        <div class="aa-ItemContent">
                                            <div class="aa-ItemContentBody itembox">
                                                <a href="/${customizedUrl}${item.itemLink}" class="itembox__main js-preventPanelClose">
                                                    <span class="itembox__hoverImg--wrapper">
                                                        <img class="aa-ItemContentImage itembox__hoverImg" src="${item.itemImageHoverLink}" alt=""/>
                                                    </span>
                                                    <div class="itembox__head">
                                                        <div class="itembox__actions">
                                                            <div class="itembox__actionsWrapper">
                                                                <button class="comparisonBtn js-addItemToComparison actionIcon" data-id="${item.itemId}">
                                                                    <i class="icon icon-produktvergleich"></i>
                                                                </button>
                                                                <button class="actionIcon js-addToFavorites" title="Zu Favoriten hinzufügen" data-id="${item.itemId}">
                                                                    <i class="icon icon-favorites"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div class="aa-ItemContentImage itembox__image">
                                                            <img class="aa-ItemContentImage" src="${item.itemImage}" alt=""/>
                                                        </div>
                                                    </div>
                                                    <div class="aa-ItemContentDescription itembox__content">
                                                        <span class="aa-ItemContentTitle itembox__name">${item.description}</span>
                                                        <span class="itemcardSummary marginBottom">${item.summary}</span>
                                                        <span class="aa-ItemContentTitle itembox__itemno">${orderNo} ${item.itemNo}</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                `;
                            },
                        }
                    },
                    {
                        sourceId: 'content', // Quelle für Content
                        getItems() {
                            return getAlgoliaResults({
                                searchClient,
                                queries: [{
                                    indexName: secondaryIndexName, // Content-Suche
                                    query,
                                    params: {
                                        hitsPerPage: 5,
                                    },
                                }],
                            });
                        },
                        templates: {
                            header() {
                                // Aktuelle URL abrufen
                                var currentUrl = window.location.href;

                                // Standard-Überschrift
                                var headline = 'Inhalte';

                                // Überprüfen, ob die URL '/en/' enthält
                                if (currentUrl.includes('/en/')) {
                                    headline = 'Content';
                                }

                                return headline;
                            },
                            item({item, components, html}) {
                                return html`
                                    <a href="${item.url}" class="searchResults__contentItem">
                                        <div class="searchResults__contentItem--contentWrapper">
                                            <div class="searchResults__contentItem--image d-none d-sm-block">
                                                <img src="${item.image?.imageUrl || '/userdata/images/logo/logo_notext.svg'}" alt=""/>
                                            </div>
                                            <div>
                                                <div class="searchResults__contentItem--headline">
                                                    ${components.Highlight({ hit: item, attribute: 'title' })}
                                                </div>
                                                <div class="searchResults__contentItem--text">
                                                    ${components.Highlight({ hit: item, attribute: 'content' })}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="button button--linkWithIcon"></div>
                                    </a>
                                `;
                            },
                        }
                    }
                ];
            },
        });
    }

    if (extraSearchBox) {
        autocomplete({
            container: '.autocomplete__extra_search',
            panelContainer: '.autocomplete__extra_results',
            placeholder: searchPlaceHolder,
            detachedMediaQuery: 'none',
            openOnFocus: true,
            onSubmit(params) {
                if (params.state.query) {
                    location.href = `/${customizedUrl}/search/?input_search=${params.state.query}`;
                }
            },
            getSources({query}) {
                return [
                    {
                        sourceId: 'products', // Quelle für Produkte
                        getItems() {
                            return getAlgoliaResults({
                                searchClient,
                                queries: [{
                                    indexName: algoliaIndexName, // Produktsuche
                                    query,
                                    params: {
                                        hitsPerPage: 12,
                                    },
                                }],
                            });
                        },
                        templates: {
                            item({item, components, html}) {
                                return html`
                                    <div class="aa-ItemWrapper">
                                        <div class="aa-ItemContent">
                                            <div class="aa-ItemContentBody itembox">
                                                <a href="/${customizedUrl}${item.itemLink}" class="itembox__main js-preventPanelClose">
                                                    <span class="itembox__hoverImg--wrapper">
                                                        <img class="aa-ItemContentImage itembox__hoverImg" src="${item.itemImageHoverLink}" alt=""/>
                                                    </span>
                                                    <div class="itembox__head">
                                                        <div class="itembox__actions">
                                                            <div class="itembox__actionsWrapper">
                                                                <button class="comparisonBtn js-addItemToComparison actionIcon" data-id="${item.itemId}">
                                                                    <i class="icon icon-produktvergleich"></i>
                                                                </button>
                                                                <button class="actionIcon js-addToFavorites" title="Zu Favoriten hinzufügen" data-id="${item.itemId}">
                                                                    <i class="icon icon-favorites"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div class="aa-ItemContentImage itembox__image">
                                                            <img class="aa-ItemContentImage" src="${item.itemImage}" alt=""/>
                                                        </div>
                                                    </div>
                                                    <div class="aa-ItemContentDescription itembox__content">
                                                        <span class="aa-ItemContentTitle itembox__name">${item.description}</span>
                                                        <span class="itemcardSummary marginBottom">${item.summary}</span>
                                                        <span class="aa-ItemContentTitle itembox__itemno">${orderNo} ${item.itemNo}</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                `;
                            },
                        }
                    },
                    {
                        sourceId: 'content', // Quelle für Content
                        getItems() {
                            return getAlgoliaResults({
                                searchClient,
                                queries: [{
                                    indexName: secondaryIndexName, // Content-Suche
                                    query,
                                    params: {
                                        hitsPerPage: 12,
                                    },
                                }],
                            });
                        },
                        templates: {
                            item({item, components, html}) {
                                return html`
                                    <div class="aa-ItemWrapper">
                                        <div class="aa-ItemContent">
                                            <div class="aa-ItemContentBody contentbox">
                                                <a href="/${customizedUrl}${item.contentLink}" class="contentbox__main js-preventPanelClose">
                                                    <div class="contentbox__head">
                                                        <span class="aa-ItemContentTitle contentbox__title">${item.title}</span>
                                                        <span class="contentbox__summary">${item.summary}</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                `;
                            },
                        }
                    }
                ];
            },
        });
    }
}

